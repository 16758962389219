import serverList from "../apis/serverList";
import axios from "axios";
import authHeader from "../apis/authHeader";

export const generateVA = (invoiced_company_id) => async (dispatch) => {
  const paymentAPI = axios.create({
    baseURL: serverList.payment,
    headers: authHeader(),
  });

  const response = await paymentAPI
    .post("/api/v1/payment/virtual-account", {
      invoiced_company_id,
    })
    .catch((e) => { });
  if (response !== undefined) {
    if (response.status === 401) {
      dispatch({
        type: "ERROR",
        payload: { status: "TOKEN_EXPIRE", message: response.data.message },
      });
    }
    if (response.data.status === "OK") {
      dispatch({ type: "GENERATE_VA", payload: response.data.data });
    }
  }
};

export const customerBalance = (partner_id) => async (dispatch) => {
  const paymentAPI = axios.create({
    baseURL: serverList.payment,
    headers: authHeader(),
  });

  const response = await paymentAPI
    .post("/api/v1/payment/balance/summary", {
      partner_id,
    })
    .catch((e) => { });
  if (response !== undefined) {
    if (response.status === 401) {
      dispatch({
        type: "ERROR",
        payload: { status: "TOKEN_EXPIRE", message: response.data.message },
      });
    }
    if (response.data.status === "OK") {
      dispatch({ type: "GET_CUSTOMER_BALANCE", payload: response.data.data });
    }
  }
};

export const companyBalance = () => async (dispatch) => {
  const paymentAPI = axios.create({
    baseURL: serverList.payment,
    headers: authHeader(),
  });

  const response = await paymentAPI
    .post("/api/v1/payment/company/balance/summary")
    .catch((e) => { });
  if (response !== undefined) {
    if (response.status === 401) {
      dispatch({
        type: "ERROR",
        payload: { status: "TOKEN_EXPIRE", message: response.data.message },
      });
    }
    if (response.data.status === "OK") {
      dispatch({ type: "GET_CUSTOMER_BALANCE", payload: response.data.data });
    }
  }
};

export const customerBalanceList = () => async (dispatch) => {
  const paymentAPI = axios.create({
    baseURL: serverList.payment,
    headers: authHeader(),
  });

  const response = await paymentAPI.get("/api/v1/payment/balance/list").catch((e) => { });
  const tempData = [];
  response.data.data.map((isi) => {
    tempData.push({
      id: isi.company_id,
      partner_id: isi.partner_id,
      company_id: isi.company_id,
      label: `
      ${isi.company_name} (${Number(isi.balance).toLocaleString("id-ID", {
        style: "currency",
        currency: "IDR",
      })})`,
    });
  });
  if (response !== undefined) {
    if (response.status === 401) {
      dispatch({
        type: "ERROR",
        payload: { status: "TOKEN_EXPIRE", message: response.data.message },
      });
    }
    if (response.data.status === "OK") {
      dispatch({ type: "GET_CUSTOMER_BALANCE_LIST", payload: tempData });
    }
  }
};

export const paymentSettlementRequest = (arrInv) => async (dispatch) => {
  const paymentAPI = axios.create({
    baseURL: serverList.payment,
    headers: authHeader(),
  });


  const response = await paymentAPI.post("/api/v1/payment/settlement/request", arrInv)
    .catch((e) => {
      let message = e.response.data.message;
      let errorMessage = message;

      if (e.response.status === 400) {
        if (/insufficient balance for CompanyID: .+, PartnerID: .+/g.test(message) == true) {
          errorMessage = "Saldo tidak mencukupi."
        }
        if (/document for invoice .+ not approved yet/g.test(message) == true) {
          errorMessage = "Dokumen invoice belum disetujui pelanggan"
        }
      }
      dispatch({
        type: "ERROR",
        payload: { status: "ERROR_REQUEST_SETTLEMENT", message: errorMessage },
      });
    });
  if (response !== undefined) {
    if (response.status === 401) {
      dispatch({
        type: "ERROR",
        payload: { status: "TOKEN_EXPIRE", message: response.data.message },
      });
    }
    if (response.data.status === "OK") {
      dispatch({
        type: "SUCCESS_REQUEST_SETTLEMENT",
        payload: { status: "SUCCESS_REQUEST_SETTLEMENT", message: "SUCCESS" },
      });
    }
  }
};

export const paymentSettlementRequestOrderFinancing = (arrInv) => async (dispatch) => {
  const paymentAPI = axios.create({
    baseURL: serverList.payment,
    headers: authHeader(),
  });
  const response = await paymentAPI.post("/api/v1/payment/settlement/request/financing/order", arrInv)
    .catch((e) => {
      let message = e.response.data.message;
      let errorMessage = message;

      if (e.response.status === 400) {
        if (/insufficient balance for CompanyID: .+, PartnerID: .+/g.test(message) == true) {
          errorMessage = "Saldo tidak mencukupi."
        }
        if (/document for invoice .+ not approved yet/g.test(message) == true) {
          errorMessage = "Dokumen invoice belum disetujui pelanggan"
        }
      }
      dispatch({
        type: "ERROR",
        payload: { status: "ERROR_REQUEST_SETTLEMENT", message: errorMessage },
      });
    });
  if (response !== undefined) {
    if (response.status === 401) {
      dispatch({
        type: "ERROR",
        payload: { status: "TOKEN_EXPIRE", message: response.data.message },
      });
    }
    if (response.data.status === "OK") {
      dispatch({
        type: "SUCCESS_REQUEST_SETTLEMENT",
        payload: { status: "SUCCESS_REQUEST_SETTLEMENT", message: "SUCCESS" },
      });
    }
  }
};

export const paymentSettlementRequestNonFinancing = (arrInv) => async (dispatch) => {
  const paymentAPI = axios.create({
    baseURL: serverList.payment,
    headers: authHeader(),
  });

  const response = await paymentAPI.post("/api/v1/payment/settlement/request/nonfinancing", arrInv)
    .catch((e) => {
      let message = e.response.data.message;
      let errorMessage = message;

      if (e.response.status === 400) {
        if (/insufficient balance for CompanyID: .+, PartnerID: .+/g.test(message) == true) {
          errorMessage = "Saldo tidak mencukupi."
        }
        if (/document for invoice .+ not approved yet/g.test(message) == true) {
          errorMessage = "Dokumen invoice belum disetujui pelanggan"
        }
      }
      dispatch({
        type: "ERROR",
        payload: { status: "ERROR_REQUEST_SETTLEMENT", message: errorMessage },
      });
    });
  if (response !== undefined) {
    if (response.status === 401) {
      dispatch({
        type: "ERROR",
        payload: { status: "TOKEN_EXPIRE", message: response.data.message },
      });
    }
    if (response.data.status === "OK") {
      dispatch({
        type: "SUCCESS_REQUEST_SETTLEMENT",
        payload: { status: "SUCCESS_REQUEST_SETTLEMENT", message: "SUCCESS" },
      });
    }
  }
};

export const paymentSettlementRequestList =
  ({ page, limit, keyword }) =>
    async (dispatch) => {
      const paymentAPI = axios.create({
        baseURL: serverList.payment,
        headers: authHeader(),
      });

      const response = await paymentAPI
        .get("/api/v1/payment/admin/settlement/request", {
          params: {
            page,
            limit,
            keyword,
          },
        })
        .catch((e) => { });

      let arrFinal = [];
      let no = 0;

      arrFinal = response.data.data.result.map((isi) => {
        let returnVal = {};
        no += 1;
        returnVal = {
          no: page === 1 ? no : no + (page - 1) * 10,
          id: isi.id,
          amount: isi.amount,
          company_id: isi.company_id,
          company_name: isi.company_name,
          created_at: isi.created_at,
          financing_id: isi.financing_id,
          financing_number: isi.financing_number,
          invoice_id: isi.invoice_id,
          ledger_id: isi.ledger_id,
          partner_id: isi.partner_id,
          remarks: isi.remarks,
          type: isi.type,
          status: isi.status,
        };
        return returnVal;
      });

      if (response !== undefined) {
        if (response.status === 401) {
          dispatch({
            type: "ERROR",
            payload: { status: "TOKEN_EXPIRE", message: response.data.message },
          });
        }
        if (response.data.status === "OK") {
          dispatch({
            type: "REQUEST_SETTLEMENT_LIST",
            payload: arrFinal,
          });
          dispatch({
            type: "REQUEST_SETTLEMENT_LIST_PAGINATION",
            payload: response.data.data.pagination,
          });
        }
      }
    };

export const paymentSettlementRequestNonFinancingList =
  ({ page, limit, keyword }) =>
    async (dispatch) => {
      const paymentAPI = axios.create({
        baseURL: serverList.payment,
        headers: authHeader(),
      });

      const response = await paymentAPI
        .get("/api/v1/payment/admin/settlement/request/nonfinancing", {
          params: {
            page,
            limit,
            keyword,
          },
        })
        .catch((e) => { });

      let arrFinal = [];
      let no = 0;

      arrFinal = response.data.data.result.map((isi) => {
        let returnVal = {};
        no += 1;
        returnVal = {
          no: page === 1 ? no : no + (page - 1) * 10,
          id: isi.id,
          amount: isi.amount,
          company_id: isi.company_id,
          company_name: isi.company_name,
          created_at: isi.created_at,
          financing_id: isi.financing_id,
          financing_number: isi.financing_number,
          invoice_id: isi.invoice_id,
          ledger_id: isi.ledger_id,
          partner_id: isi.partner_id,
          remarks: isi.remarks,
          type: isi.type,
          status: isi.status,
        };
        return returnVal;
      });

      if (response !== undefined) {
        if (response.status === 401) {
          dispatch({
            type: "ERROR",
            payload: { status: "TOKEN_EXPIRE", message: response.data.message },
          });
        }
        if (response.data.status === "OK") {
          dispatch({
            type: "REQUEST_SETTLEMENT_LIST_NON_FINANCING",
            payload: arrFinal,
          });
          dispatch({
            type: "REQUEST_SETTLEMENT_LIST_NON_FINANCING_PAGINATION",
            payload: response.data.data.pagination,
          });
        }
      }
    };

export const paymentSettlementRequestDetail =
  ({ financing_id }) =>
    async (dispatch) => {
      const paymentAPI = axios.create({
        baseURL: serverList.payment,
        headers: authHeader(),
      });

      const response = await paymentAPI
        .get(`/api/v1/payment/admin/settlement/request/detail?financing_id=${financing_id}`)
        .catch((e) => { });

      if (response !== undefined) {
        if (response.status === 401) {
          dispatch({
            type: "ERROR",
            payload: { status: "TOKEN_EXPIRE", message: response.data.message },
          });
        }
        if (response.data.status === "OK") {
          dispatch({
            type: "REQUEST_SETTLEMENT_DETAIL",
            payload: response.data.data,
          });
        }
      }
    };

export const paymentSettlementRequestDetailNonFinancing =
  ({ settlement_id }) =>
    async (dispatch) => {
      const paymentAPI = axios.create({
        baseURL: serverList.payment,
        headers: authHeader(),
      });

      const response = await paymentAPI
        .get(
          `/api/v1/payment/admin/settlement/request/detail/nonfinancing?settlement_id=${settlement_id}`
        )
        .catch((e) => { });

      if (response !== undefined) {
        if (response.status === 401) {
          dispatch({
            type: "ERROR",
            payload: { status: "TOKEN_EXPIRE", message: response.data.message },
          });
        }
        if (response.data.status === "OK") {
          dispatch({
            type: "REQUEST_SETTLEMENT_DETAIL",
            payload: response.data.data,
          });
        }
      }
    };

export const createAdminSettlementRequest =
  ({ data }) =>
    async (dispatch) => {
      const paymentAPI = axios.create({
        baseURL: serverList.payment,
        headers: authHeader(),
      });

      const response = await paymentAPI
        .post(`/api/v1/payment/admin/settlement/disbursement`, data)
        .catch((e) => { });

      if (response !== undefined) {
        if (response.status === 401) {
          dispatch({
            type: "ERROR",
            payload: { status: "TOKEN_EXPIRE", message: response.data.message },
          });
        }
        if (response.data.status === "OK") {
          dispatch({
            type: "SUCCESS_CREATE_REQUEST_SETTLEMENT",
            payload: { status: "SUCCESS_CREATE_REQUEST_SETTLEMENT", message: "SUCCESS" },
          });
        }
      } else {
        dispatch({
          type: "ERROR_CREATE_REQUEST_SETTLEMENT",
          payload: { status: "ERROR_CREATE_REQUEST_SETTLEMENT", message: "ERROR" },
        });
      }
    };

export const createAdminSettlementRequestNonFinancing =
  ({ data }) =>
    async (dispatch) => {
      const paymentAPI = axios.create({
        baseURL: serverList.payment,
        headers: authHeader(),
      });

      const response = await paymentAPI
        .post(`/api/v1/payment/admin/settlement/disbursement/nonfinancing`, data)
        .catch((e) => { });

      if (response !== undefined) {
        if (response.status === 401) {
          dispatch({
            type: "ERROR",
            payload: { status: "TOKEN_EXPIRE", message: response.data.message },
          });
        }
        if (response.data.status === "OK") {
          dispatch({
            type: "SUCCESS_CREATE_REQUEST_SETTLEMENT",
            payload: { status: "SUCCESS_CREATE_REQUEST_SETTLEMENT", message: "SUCCESS" },
          });
        }
      } else {
        dispatch({
          type: "ERROR_CREATE_REQUEST_SETTLEMENT",
          payload: { status: "ERROR_CREATE_REQUEST_SETTLEMENT", message: "ERROR" },
        });
      }
    };
